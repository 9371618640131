import './App.css';
import Content from './component/Content';
import Button from './component/Button';
import { useEffect, useState } from 'react';
import { flowerSamples } from './constants.js';

function App() {
  // const [scrollPosition, setScrollPosition] = useState(0);
    const [isScrolledUp, setIsScrolledUp] = useState(false);
    
    useEffect(() => {
        const handleScroll = () => {
        const position = window.scrollY;
        // setScrollPosition(position);
        setIsScrolledUp(position >= 200);
        };

        window.addEventListener('scroll', handleScroll);
        
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <div className="App flex flex-col items-center w-screen h-screen overflow-x-hidden">
      <header className={`flex min-h-[100px] items-center justify-center w-screen z-20 top-0 ${isScrolledUp && 'fixed top-0 left-0 bg-white shadow-lg'}`}>
        <Content children={<section className="w-full flex items-center justify-between">
            <div className="flex items-center">
              <i className="fab fa-pinterest mr-3 text-[2.5rem]"/>
              <button className="flex items-center px-4 h-10 rounded-3xl bg-gray-100 text-black">
                <i className="fa fa-bars mr-2 text-sm"></i>
                <b className="text-sm">Explore</b>
              </button>
            </div>
            <div className="w-[280px] lg:w-[350px] hidden sm:flex items-center justify-between text-sm">
              <a href="#home" className=''>Home</a>
              <a href="#projects" className=''>Projects</a>
              <a href="#about" className=''>About us</a>
              <a href="#services" className=''>Services</a>
            </div>
            <Button text={`Contact us`}/>
          </section>}/>
      </header>

      <Content children={<>
          <div className="size-[20rem] xs:size-[30rem] shadow-xl border-[1rem] border-gray-200 rounded-full bg-gray-100 absolute -right-[7rem] xs:-right-[10rem] -top-[7rem] xs:-top-[10rem]"/>
          <div className="size-[12rem] md:hidden shadow-xl border-[1rem] border-gray-200 rounded-full bg-gray-100 absolute -left-16 sm:-bottom-10"/>

          <section className="w-full py-14 sm:py-20 md:py-32 flex md:flex-row flex-col md:items-center justify-between text-left z-10">
            <div className="flex flex-col items-start w-full sm:w-[50%] mr-3 md:mb-0 mb-10 relative">
              <b className='z-10'>MG COMPANY</b>
              <b className="font-[900] text-[2.5rem] xs:text-[3.5rem] md:text-[3rem] lg:text-[3.5rem] xl:text-[4rem] leading-[3rem] xs:leading-[4rem] z-10">Creative Landscaping you can rely on</b>
              <div className="size-[12rem] shadow-xl border-[1rem] border-gray-200 rounded-full bg-gray-100 absolute -left-16 -bottom-20 md:block hidden"/>
            </div>
            <div className="flex flex-col items-start w-full sm:w-[50%] z-10">
              <p className='xs:text-base text-sm'>Our creative landscape solutions create an arsthetically stunning garden or lawn design and inspire you to create magical moments that you will cherish!</p>
              <div className="flex items-center mt-4">
                <i className="fab fa-whatsapp mr-4 text-xl xs:text-2xl"></i>
                <i className="fab fa-facebook mr-4 text-xl xs:text-2xl"></i>
                <i className="fa fa-envelope mr-4 text-xl xs:text-2xl"></i>
              </div>
              <Button text={`Order a product`} className={`bg-black text-white px-10 mt-6`} />
            </div>
          </section>
        </>}/>
      
      <section className="w-full flex place-items-center justify-center items-center relative px-5">
        <Content className={'relative  place-items-center top-[3rem] md:top-[5rem] lg:top-[7rem] xl:top-[10rem] h-[320px] sm:h-[350px] lg:h-[500px]'} children={<>
          <section className="absolute w-full flex lg:flex-row flex-col h-full items-center  sm:px-20">
            <div className="flex jb sm:justify-center items-center w-full sm:w-[200px] lg:mb-0 sm:mb-6 mb-3 ">
              <aside className="size-8 sm:size-10 md:size-14 rounded-full bg-[#DA7241] flex items-center justify-center text-white mr-5">
                <i className="fa fa-arrow-left"></i>
              </aside>
              <aside className="size-8 sm:size-10 md:size-14 rounded-full bg-[#000000] flex items-center justify-center text-white">
                <i className="fa fa-arrow-right"></i>
              </aside>
            </div>
            <div className="flex-1 h-full justify-center items-center lg:flex hidden flex-row-reverse relative place-items-center">
              <div className="w-full h-[70%] shadow-xl overflow-hidden rounded-lg">
                <img src={flowerSamples[0]} alt="" className="w-full h-full object-cover" />
              </div>
              <div className="w-[95%] h-[85%] shadow-xl overflow-hidden rounded-lg absolute  right-0">
                <img src={flowerSamples[2]} alt="" className="w-full h-full object-cover" />
              </div>
              <div className="w-[90%] h-full shadow-xl overflow-hidden rounded-lg absolute  right-0">
                <img src={flowerSamples[1]} alt="" className="w-full h-full object-cover" />
              </div>
            </div>
            <div className="flex-1 w-full h-full justify-center items-center lg:hidden flex flex-row-reverse relative place-items-center">
              <div className="w-full h-[70%] shadow-xl overflow-hidden rounded-lg left-0 ">
                <img src={flowerSamples[0]} alt="" className="w-full h-full object-cover" />
              </div>
              <div className="w-[80%] xs:w-[90%] h-full z-10 shadow-xl overflow-hidden rounded-lg absolute">
                <img src={flowerSamples[2]} alt="" className="w-full h-full object-cover" />
              </div>
              <div className="w-[60%] h-[70%] shadow-xl overflow-hidden rounded-lg absolute  right-0">
                <img src={flowerSamples[1]} alt="" className="w-full h-full object-cover" />
              </div>
            </div>
          </section>
          </>}/>
      </section>


      <section className='bg-black w-full pt-[3rem] xs:pt-[5rem] sm:pt-[7rem] lg:pt-[10rem] pb-[5rem] xs:pb-[10rem] sm:pb-[17rem] md:pb-[7rem] flex justify-center items-center'>
        <Content className={``} children={<>
          <section className='py-10 xs:py-14 sm:py-20 md:py-32 w-full text-white text-left'>
            <div className="w-full flex sm:flex-row flex-col sm:items-center items-start justify-between">
              <div className="flex flex-col items-start sm:w-[500px]">
                <h1 className="text-3xl font-bold">Our Services</h1>
                <small>Our expert team of designers will help you create the outdoor space of your dreams. Whether refreshing the parts in your yard or planning a complete outdoor transformation, we have an offer for you!</small>
              </div>
              <div className="flex items-center text-[#DA7241] sm:mt-0 mt-5">
                <p>View all</p>
                <i className="fa fa-arrow-right ml-3"></i>
              </div>
            </div>
            
            <section className="w-full overflow-x-auto mt-10 sm:mt-20">
              <div className="w-fit flex">
                {
                  [1,2,3,4,5,6,7,8,9].map((val, idx)=><div key={idx} className='flex flex-col hover:bg-[#DA7241] h-[400px] border border-gray-700 p-4 pt-6 bg-transparent w-[250px] mr-5 rounded-lg'>
                    <b className="text-lg mb-3">Lighting design</b>
                    <small>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni ut voluptatibus minus eos unde veritatis in soluta qui consequatur odit?</small>
                    <br />
                    <div className="w-full flex-1">
                      <img src="https://plus.unsplash.com/premium_photo-1730988915408-209c1ab59554?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" className="w-full h-full object-cover" />
                    </div>
                  </div>)
                }
              </div>
            </section>
          </section>
          </>}/>
      </section>

      <section className="w-full flex place-items-center justify-center items-center relative">
        <Content className={'relative md:bottom-[6rem] h-[550px]'} children={<>
          <section className="absolute w-full flex md:flex-row-reverse flex-col-reverse h-full items-center px-4 sm:px-10 lg:px-20 text-center md:text-left">
            <div className="flex flex-col justify-center items-center md:items-start w-[300px] lg:w-[400px] ml-6 lg:ml-10 py-10">
              <h1 className="text-2xl font-bold">Our last project:<br/>mg-Flora</h1>
              <p className="font-semibold my-2">Get Natural Flowers</p>
              <p className="text-gray-600 my-4 lg:text-base text-sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere mollitia distinctio quis odio veritatis itaque voluptate, dolore quas cupiditate commodi.</p>
              <br />
              <Button className={`border-[#DA7241] text-[#DA7241]`} text={`Check it out`}/>
            </div>
            <div className="flex-1 h-full justify-center items-center md:flex hidden flex-row-reverse relative place-items-center">
              <div className="w-full h-[70%] shadow-xl overflow-hidden rounded-lg">
                <img src={flowerSamples[0]} alt="" className="w-full h-full object-cover" />
              </div>
              <div className="w-[95%] h-[85%] shadow-xl overflow-hidden rounded-lg absolute  right-0">
                <img src={flowerSamples[2]} alt="" className="w-full h-full object-cover" />
              </div>
              <div className="w-[90%] h-full shadow-xl overflow-hidden rounded-lg absolute  right-0">
                <img src={flowerSamples[1]} alt="" className="w-full h-full object-cover" />
              </div>
            </div>
            <div className="flex-1 w-full h-full justify-center items-center md:hidden flex flex-row-reverse relative place-items-center">
              <div className="w-full h-[70%] shadow-xl overflow-hidden rounded-lg left-0 ">
                <img src={flowerSamples[0]} alt="" className="w-full h-full object-cover" />
              </div>
              <div className="w-[80%] xs:w-[90%] h-full z-10 shadow-xl overflow-hidden rounded-lg absolute">
                <img src={flowerSamples[2]} alt="" className="w-full h-full object-cover" />
              </div>
              <div className="w-[60%] h-[70%] shadow-xl overflow-hidden rounded-lg absolute  right-0">
                <img src={flowerSamples[1]} alt="" className="w-full h-full object-cover" />
              </div>
            </div>
          </section>
          </>}/>
      </section>

      <footer className="w-full bg-black flex items-center flex-col justify-center text-white py-24">
        <Content children={<>
          <section className="w-full flex sm:flex-row flex-col justify-between items-start">
            <div className="flex flex-col xs:items-start items-center xs:w-auto w-full ">
              <aside className="flex xs:flex-row flex-col items-center mb-8 text-[#DA7241]">
                <i className="fab fa-pinterest xs:mr-4 text-[3rem] lg:text-[4rem] xs:mb-0 mb-3"></i>
                <b className="text-2xl lg:text-4xl">Mg-Company</b>
              </aside>
              <small className='mb-3 text-gray-400'>mgcompany@gmail.com</small>
              <small className='mb-3 text-gray-400'>+233542893124</small>
            </div>
            <div className="flex items-start justify-center xs:justify-between sm:mt-0 mt-5 w-full sm:w-[300px] md:w-[350px] lg:w-[500px]">
              <aside className="flex flex-col xs:items-start items-center xs:mr-0 mr-20">
                <b className='mb-6'>Menu</b>
                <small className='mb-3 text-gray-400'>Privacy Policy</small>
                <small className='mb-3 text-gray-400'>Privacy Policy</small>
                <small className='mb-3 text-gray-400'>Privacy Policy</small>
                <small className='mb-3 text-gray-400'>Privacy Policy</small>
              </aside>
              <aside className="flex flex-col xs:items-start items-center">
                <b className='mb-6'>Services</b>
                <small className='mb-3 text-gray-400'>Privacy Policy</small>
                <small className='mb-3 text-gray-400'>Privacy Policy</small>
                <small className='mb-3 text-gray-400'>Privacy Policy</small>
                <small className='mb-3 text-gray-400'>Privacy Policy</small>
              </aside>
              <aside className="hidden xs:flex flex-col items-start">
                <i className="fab fa-facebook text-xl mb-4"></i>
                <i className="fa fa-envelope text-xl mb-4"></i>
                <i className="fab fa-whatsapp text-xl mb-4"></i>
                <i className="fab fa-youtube text-xl"></i>
              </aside>
            </div>
              <aside className="xs:hidden flex items-center justify-center w-full  mt-5">
                <i className="fab fa-facebook text-xl mr-8"></i>
                <i className="fa fa-envelope text-xl mr-8"></i>
                <i className="fab fa-whatsapp text-xl mr-8"></i>
                <i className="fab fa-youtube text-xl"></i>
              </aside>
          </section>
          </>}/>
      </footer>
    </div>
  );
}

export default App;
